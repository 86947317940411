
*{
    font-family: 'Geologica', sans-serif;
    font-family: 'Outfit', sans-serif;
    }

.css-ha3bif-MuiTimelineItem-root:before{
    display: none;
}
.logo-image{
    max-width: 200px;
}